import React, { useRef, useEffect } from 'react';
import { CircularProgress, Typography, useTheme } from '@mui/material';
import { useBatteryConfig } from 'hooks/plant/useBatteryConfig';
import HexagonOverviewLight from 'assets/singleline/hexagon_overview_light.png';
import HexagonOverviewDark from 'assets/singleline/hexagon_overview_dark.png';
import { useIsDarkTheme } from 'hooks/useThemeMode';

const CONTAINER_WIDTH = 1289;
const CONTAINER_HEIGHT = 521;

interface HexagonOverviewCanvasProps {
  plantId: string;
  batteryId: string | number;
}

const HexagonOverviewCanvas: React.FC<HexagonOverviewCanvasProps> = ({ plantId, batteryId }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const theme = useTheme();
  const isDarkTheme = useIsDarkTheme();
  const { data: battery, isLoading, error } = useBatteryConfig(plantId, batteryId);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const backgroundImage = new Image();
    backgroundImage.src = isDarkTheme ? HexagonOverviewDark : HexagonOverviewLight;

    backgroundImage.onload = () => {
      ctx.clearRect(0, 0, CONTAINER_WIDTH, CONTAINER_HEIGHT);
      ctx.drawImage(backgroundImage, 0, 0, CONTAINER_WIDTH, CONTAINER_HEIGHT);

      if (battery?.asset?.inverter) {
        const { inverter } = battery.asset;
        const { totalEffectivePower, totalApparentPower, totalReactivePower, powerFactor } = inverter;
        const voltageA = inverter.totalApparentPower?.phases?.A || 0;
        const voltageB = inverter.totalApparentPower?.phases?.B || 0;
        const currentA = inverter.totalEffectivePower?.phases?.A || 0;

        const h1Size = `${theme.typography.h1.fontSize}` || '96px';
        const h2Size = `${theme.typography.h2.fontSize}` || '60px';
        const h4Size = `${theme.typography.h4.fontSize}` || '34px';
        const h5Size = `${theme.typography.h5.fontSize}` || '24px';
        const body1Size = `${theme.typography.body1.fontSize}` || '16px';

        // Define value-label pairs for horizontal text
        const horizontalTextItems = [
          { label: 'B-N', value: `${voltageA} kV`, x: 300, y: 65 },
          { label: 'C-V', value: `${voltageA} kV`, x: 300, y: 135 },
          { label: 'B-C', value: `${voltageB} kV`, x: 300, y: 230 },
          { label: 'C-A', value: `${voltageB} kV`, x: 300, y: 298 },
          { label: 'B', value: `${currentA} A`, x: 300, y: 390 },
          { label: 'C', value: `${currentA} A`, x: 300, y: 460 },
        ];

        // Define value-label pairs for regular text
        const regularTextItems = [
          {
            value: `${voltageA} kV`,
            label: 'A-N Voltage',
            x: 95,
            y: 90,
            valueFontSize: h4Size,
            labelFontSize: body1Size,
            labelYOffset: 24,
          },
          {
            value: `${voltageB} kV`,
            label: 'A-B Voltage',
            x: 95,
            y: 255,
            valueFontSize: h4Size,
            labelFontSize: body1Size,
            labelYOffset: 24,
          },
          {
            value: `${currentA} A`,
            label: 'A Current',
            x: 95,
            y: 415,
            valueFontSize: h4Size,
            labelFontSize: body1Size,
            labelYOffset: 24,
          },
          {
            value: `${totalApparentPower?.value || 0} kVA`,
            label: 'Total Apparent Power',
            x: 1110,
            y: 75,
            valueFontSize: h2Size,
            labelFontSize: h5Size,
            labelYOffset: 32,
          },
          {
            value: `${totalReactivePower?.value || 0} var`,
            label: 'Total Reactive Power',
            x: 1110,
            y: 265,
            valueFontSize: h2Size,
            labelFontSize: h5Size,
            labelYOffset: 32,
          },
          {
            value: `${totalEffectivePower?.value || 0} kW`,
            label: 'Total Effective Power',
            x: 1110,
            y: 440,
            valueFontSize: h2Size,
            labelFontSize: h5Size,
            labelYOffset: 32,
          },
          {
            value: `${powerFactor?.toFixed(2) || 0} cap`,
            label: 'Power Factor',
            x: 680,
            y: 240,
            valueFontSize: h1Size,
            labelFontSize: h2Size,
            labelYOffset: 80,
          },
        ];

        // Render horizontal text items
        horizontalTextItems.forEach(({ label, value, x, y }) => {
          drawTextHorizontal(ctx, label, value, x, y, h5Size, theme.palette.text.secondary, theme.palette.text.primary);
        });

        // Render regular text items
        regularTextItems.forEach(({ value, label, x, y, valueFontSize, labelFontSize, labelYOffset }) => {
          drawText(
            ctx,
            value,
            label,
            x,
            y,
            valueFontSize,
            labelFontSize,
            theme.palette.text.primary,
            theme.palette.text.secondary,
            labelYOffset
          );
        });
      }
    };
  }, [battery, theme, isDarkTheme]);

  const drawText = (
    ctx: CanvasRenderingContext2D,
    value: string,
    label: string,
    x: number,
    y: number,
    valueFontSize: string,
    labelFontSize: string,
    valueColor: string,
    labelColor: string,
    labelYOffset: number
  ) => {
    // Center-align value text
    ctx.fillStyle = valueColor;
    ctx.font = `${valueFontSize} RRPioneer, Helvetica, Arial, sans-serif`;
    const valueWidth = ctx.measureText(value).width;
    ctx.fillText(value, x - valueWidth / 2, y); // Adjust x to center the value

    // Center-align label text if present
    if (label && labelFontSize) {
      ctx.fillStyle = labelColor;
      ctx.font = `${labelFontSize} RRPioneer, Helvetica, Arial, sans-serif`;
      const labelWidth = ctx.measureText(label).width;
      ctx.fillText(label, x - labelWidth / 2, y + labelYOffset); // Adjust x to center the label
    }
  };
  const drawTextHorizontal = (
    ctx: CanvasRenderingContext2D,
    label: string,
    value: string,
    x: number,
    y: number,
    fontSize: string,
    labelColor: string,
    valueColor: string
  ) => {
    ctx.fillStyle = labelColor;
    ctx.font = `${fontSize} RRPioneer, Helvetica, Arial, sans-serif`;

    const labelWidth = ctx.measureText(label).width;
    const valueWidth = ctx.measureText(value).width;

    // Center the entire label+value text horizontally
    const totalWidth = labelWidth + 5 + valueWidth;
    const centeredX = x - totalWidth / 2;

    ctx.fillText(label, centeredX, y);
    ctx.fillStyle = valueColor;
    ctx.fillText(value, centeredX + labelWidth + 5, y); // 5px padding between label and value
  };

  if (isLoading) return <CircularProgress />;
  if (error || !battery) return <Typography>Error loading battery data</Typography>;

  return (
    <div style={{ maxWidth: '100%' }}>
      <canvas ref={canvasRef} width={CONTAINER_WIDTH} height={CONTAINER_HEIGHT} style={{ width: '100%', height: 'auto' }} />
    </div>
  );
};

export default HexagonOverviewCanvas;
