import React, { ReactElement } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

type AssetGaugeProps = {
  value: number;
  max?: number;
  min?: number;
  unit?: string;
  caption?: string;
  icon?: ReactElement;
  type?: 'default' | 'distributed';
};

// Main gauge component
export const AssetGauge: React.FC<AssetGaugeProps> = ({ value, min = 0, max = 100, unit, icon, caption, type = 'default' }) => {
  const theme = useTheme();
  const totalTicks = 37;

  // Dynamically calculate critical and warning tick ranges
  const criticalTicks = Math.floor(totalTicks * 0.12); // 12% for critical
  const warnTicks = Math.floor(totalTicks * 0.18); // 18% for warning

  const indicatorValue = (((value ?? 0) - min) / (max - min)) * totalTicks; // Map value to ticks
  let cursorColour = theme.palette.ok.main;
  if (type === 'default') {
    if (indicatorValue <= criticalTicks) {
      cursorColour = theme.palette.critical.main;
    } else if (indicatorValue <= criticalTicks + warnTicks) {
      cursorColour = theme.palette.warn.main;
    }
  } else if (type === 'distributed') {
    if (indicatorValue <= criticalTicks || indicatorValue >= totalTicks - criticalTicks) {
      cursorColour = theme.palette.critical.main;
    } else if (
      (indicatorValue <= criticalTicks + warnTicks && indicatorValue > criticalTicks) ||
      (indicatorValue >= totalTicks - (criticalTicks + warnTicks) && indicatorValue < totalTicks - criticalTicks)
    ) {
      cursorColour = theme.palette.warn.main;
    }
  }

  return (
    <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', px: 1, pb: 2 }}>
      {icon && (
        <Box sx={{ position: 'absolute', left: '50%', top: '30%', transform: 'translate(-50%, -50%)' }}>
          <Box sx={{ color: cursorColour }}>{icon}</Box>
        </Box>
      )}
      {unit && (
        <Box sx={{ position: 'absolute', left: '50%', top: '65%', transform: 'translate(-50%, -50%)' }}>
          <Typography variant="body1" color="textSecondary">
            {unit}
          </Typography>
        </Box>
      )}
      {caption && (
        <Box sx={{ position: 'absolute', left: '0', bottom: 0, width: '100%' }}>
          <Typography variant="body1" color="textSecondary" align="center">
            {caption}
          </Typography>
        </Box>
      )}
      <Typography
        textAlign="center"
        variant="h3"
        color={cursorColour}
        fontWeight="bold"
        sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
        {value}
      </Typography>

      <GaugeTicks
        totalDegrees={280}
        tickCount={totalTicks}
        innerRadius={95}
        outerRadius={110}
        indicatorValue={indicatorValue}
        criticalTicks={criticalTicks}
        warnTicks={warnTicks}
        type={type}
      />
    </Box>
  );
};

export default AssetGauge;

// Component to generate the tick marks for the gauge
type GaugeTicksProps = {
  totalDegrees: number;
  tickCount: number;
  innerRadius: number;
  outerRadius: number;
  strokeWidth?: number;
  indicatorValue: number;
  criticalTicks: number;
  warnTicks: number;
  type: 'default' | 'distributed';
};

const GaugeTicks: React.FC<GaugeTicksProps> = ({
  totalDegrees,
  tickCount,
  innerRadius,
  outerRadius,
  strokeWidth = 4,
  indicatorValue,
  criticalTicks,
  warnTicks,
  type,
}) => {
  const theme = useTheme();
  const angleBetweenTicks = totalDegrees / (tickCount - 1);
  const ticks = [];

  // Generate each tick with the appropriate color
  for (let i = 0; i < tickCount; i++) {
    const rotationAngle = i * angleBetweenTicks;
    let strokeColor = theme.palette.ok.main;

    // Color ticks based on the gauge type
    if (type === 'default') {
      if (i < criticalTicks) {
        strokeColor = theme.palette.critical.main;
      } else if (i < criticalTicks + warnTicks) {
        strokeColor = theme.palette.warn.main;
      }
    } else if (type === 'distributed') {
      if (i < criticalTicks || i >= tickCount - criticalTicks) {
        strokeColor = theme.palette.critical.main;
      } else if (i < criticalTicks + warnTicks || i >= tickCount - (criticalTicks + warnTicks)) {
        strokeColor = theme.palette.warn.main;
      }
    }

    // Gray out ticks above the indicator value
    if (i > indicatorValue) {
      strokeColor = theme.palette.grey[400];
    }

    ticks.push(
      <line
        key={i}
        x1="0"
        y1={-innerRadius}
        x2="0"
        y2={-outerRadius}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        transform={`rotate(${rotationAngle})`}
        style={{ transition: 'all 0.3s ease' }}
      />
    );
  }

  return (
    <svg
      width={outerRadius * 2}
      height={outerRadius * 2}
      viewBox={`0 0 ${outerRadius * 2} ${outerRadius * 2}`}
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="true"
      transform={`rotate(${-totalDegrees / 2})`}
      style={{ maxWidth: '100%' }}>
      <g transform={`translate(${outerRadius}, ${outerRadius})`}>{ticks}</g>
    </svg>
  );
};
