import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { SingleLineBESS } from '../SingleLineBESS';
import { BESS } from '../SingleLineCircuit';
import StatusCarousel from '../StatusCarousel';
import { SingleLineAlarms } from '../SingleLineAlarms';
import HexagonOverview from './HexagonOverview';
import { AssetGauge } from 'components/Gauges/AssetGauge';
import { Bolt, PowerOutlined, VibrationOutlined, Visibility, BatteryChargingFull, ThermostatOutlined } from '@mui/icons-material';
import HexagonOverviewCanvas from './HexagonOverviewCanvas';

type SingleLineAssetProps = {
  battery: BESS;
};

const SingleLineAsset: React.FC<SingleLineAssetProps> = ({ battery }) => {
  const { asset } = battery.battery;

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={4} alignItems="start">
        <Grid item xs={12} md={3} container spacing={2} alignItems="start">
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              ESS
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SingleLineBESS bess={battery} onClick={() => false} sx={{ maxWidth: 'none', height: '100%', p: 3 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              Status
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 1 }}>
              <StatusCarousel operatingState={asset?.operatingState ?? 0} />
            </Paper>
          </Grid>
        </Grid>

        <Grid item xs={12} md={9} container spacing={2}>
          <Grid item xs={12} sm={2} md={3}>
            <AssetGauge
              value={asset?.inverter?.totalEffectivePower?.value ?? 0}
              unit="kW"
              icon={<PowerOutlined fontSize="large" />}
              caption="Effective Power"
            />
          </Grid>

          <Grid item xs={12} sm={2} md={3}>
            <AssetGauge
              type="distributed"
              value={asset?.inverter?.totalApparentPower?.value ?? 0}
              unit="kV"
              icon={<Bolt fontSize="large" />}
              caption="Voltage"
            />
          </Grid>

          <Grid item xs={12} sm={2} md={3}>
            <AssetGauge
              value={asset?.inverter?.totalReactivePower?.value ?? 0}
              unit="kVA"
              icon={<Visibility fontSize="large" />}
              caption="Apparent Power"
            />
          </Grid>

          <Grid item xs={12} sm={2} md={3}>
            <AssetGauge
              type="distributed"
              value={asset?.inverter?.frequency ?? 0}
              unit="Hz"
              icon={<VibrationOutlined fontSize="large" />}
              caption="Frequency"
            />
          </Grid>
        </Grid>

        <Grid
          item
          container
          sx={{
            justifyContent: 'flex-end',
          }}>
          <Grid item xs={12} md={9}>
            <HexagonOverviewCanvas plantId={battery.battery.plantId} batteryId={battery.battery.id} />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              Batteries
            </Typography>
          </Grid>
          <Grid item>
            <AssetGauge
              value={asset?.batteries?.minModuleTemp ?? 0}
              unit="°C"
              caption="Min. Module Temp"
              icon={<ThermostatOutlined fontSize="large" />}
            />
          </Grid>

          <Grid item>
            <AssetGauge
              value={asset?.batteries?.maxModuleTemp ?? 0}
              unit="°C"
              caption="Max. Module Temp"
              icon={<ThermostatOutlined fontSize="large" />}
            />
          </Grid>

          <Grid item>
            <AssetGauge
              type="distributed"
              value={asset?.batteries?.stateOfChargeMin ?? 0}
              unit="%"
              caption="Min. State of Charge"
              icon={<BatteryChargingFull fontSize="large" />}
            />
          </Grid>

          <Grid item>
            <AssetGauge
              type="distributed"
              value={asset?.batteries?.stateOfCharge ?? 0}
              unit="%"
              caption="State of Charge"
              icon={<BatteryChargingFull fontSize="large" />}
            />
          </Grid>

          <Grid item>
            <AssetGauge
              value={asset?.batteries?.stateOfChargeMax ?? 0}
              unit="%"
              caption="Max. State of Charge "
              icon={<BatteryChargingFull fontSize="large" />}
            />
          </Grid>

          <Grid item>
            <AssetGauge
              value={asset?.batteries?.minModuleVoltage ?? 0}
              unit="V"
              caption="Min. Module Voltage"
              icon={<Bolt fontSize="large" />}
            />
          </Grid>

          <Grid item>
            <AssetGauge
              value={asset?.batteries?.maxModuleVoltage ?? 0}
              unit="V"
              caption="Max. Module Voltage"
              icon={<Bolt fontSize="large" />}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <SingleLineAlarms clientId={battery.battery.plantId} batteryId={battery.battery.id} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SingleLineAsset;
